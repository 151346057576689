<template>
  <div class="expanded-items">
    <div class="options">
      <StTransitionExpand>
        <div v-if="isExpanded" class="options-column">
          <slot name="options-title" />
          <div
            v-if="items?.length"
            class="options-wrapper"
            data-t="select-options"
          >
            <Option
              v-for="item in items"
              :key="item.id"
              :item="item"
              :size="size"
              :is-selected="selectedValue === item.id"
              @option-clicked="emit('handleOptionClicked', item.id)"
            />
          </div>
          <div v-else>
            <div class="empty-list">
              <slot name="empty-list"></slot>
            </div>
          </div>
          <div
            v-if="actionButtonText"
            class="actions"
            data-t="actions"
            @click="emit('handleDropdownClick')"
          >
            <StIcon
              v-if="actionButtonPrefixIcon"
              :name="actionButtonPrefixIcon"
            />
            {{ actionButtonText }}
            <StIcon
              v-if="actionButtonPostfixIcon"
              :name="actionButtonPostfixIcon"
            />
          </div>
        </div>
      </StTransitionExpand>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IconName } from '@st/ui/components/StIcon/types'
import type { SelectItem } from '../types'
import Option from './Option.vue'

defineProps<{
  items: SelectItem[]
  size: 's' | 'm'
  isExpanded: boolean
  selectedValue: number | string | undefined
  actionButtonPrefixIcon?: IconName
  actionButtonPostfixIcon?: IconName
  actionButtonText?: string
}>()

const emit = defineEmits<{
  (e: 'handleDropdownClick'): void
  (e: 'handleOptionClicked', value: number | string): void
}>()
</script>

<style scoped>
.expanded-items {
  cursor: pointer;
  position: relative;
  display: flex;
  width: 100%;
}

.options-wrapper {
  scrollbar-width: none;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }
}

.options {
  position: absolute;
  z-index: 999;
  top: var(--spacing-100);
  right: 0;

  overflow: hidden;

  width: max-content;
  min-width: 100%;

  background-color: var(--background-secondary);
  border-radius: var(--border-radius-100);
  box-shadow:
    0 8px 12px -4px rgb(0 0 0 / 32%),
    0 16px 24px -4px rgb(0 0 0 / 24%);
}

.actions {
  cursor: pointer;

  display: flex;
  gap: var(--spacing-100);
  align-items: center;
  justify-content: center;

  padding: var(--spacing-100) 0;

  font: var(--desktop-text-sm-semibold);
  color: var(--button-primary-default);
}

.options-column {
  overflow: hidden;
  overflow-y: auto;
  gap: var(--spacing-025);

  width: 100%;
  max-height: 480px;
  padding: var(--spacing-050);
}
</style>
